import React from 'react'
import "./style.css"

const Filler = () => {
  return (
    <div className="section filler-section">
      <div className="overlay"></div>
      <div className="container filler-container">
        <h1 className="heading center-txt-h text-quote">“innovation doesn't follow a straight path… it’s a journey filled with twists and turns. xipi.tech walks with you every step of the way.”</h1>
      </div>
    </div>
  )
}

export default Filler