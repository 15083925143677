import React from 'react'
import "./style.css"
import { ReactComponent as Logo } from '../../assets/logo.svg';

const About = () => {
  return (
    <div id="about" className="section about-section">
      <div className="container about-container">
        <a className="center-h" href="#">
          <Logo className="logo" />
        </a>
        <div className="heading center-txt-h">futuristic solutions</div>
        <div className="content punch-line center-txt-h">unleash the potential of AI/ML, immersive technologies, and RPA in your process with customer-centric software development.</div>
        <div className="content tag-line center-txt-h">. . . embrace the future . . .</div>
        <div className="content center-txt-h">. . . with xipi.tech . . .</div>
        <div className="btns-stack">
          <a className="btn" href="#tech">explore more</a>
          <a className="btn cta" href="#contact">connect with us</a>
        </div>
      </div>
    </div>
  )
}

export default About