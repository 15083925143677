import React from 'react'
import "./style.css"
import {ReactComponent as Star} from "../../assets/star.svg";

const services = [
  {id:0, head: "customized softwares", descr:"tailor-made solutions designed to meet your unique business needs."},
  {id:1, head: "AI/ML integrations", descr:"empower work processes and improve efficiency with artificial intelligence and machine learning."},
  {id:2, head: "immersive experiences", descr:"crafting AR/VR experiences to revolutionize the way your customers interact."},
  {id:3, head: "robotic process automation", descr:"automate tedious tasks, optimize workflow, and enhance productivity through RPA."},
  {id:4, head: "ongoing support", descr:"reliable and prompt assistance to ensure seamless functioning of your software."},
  {id:5, head: "future-proof solutions", descr:"stay ahead of the game with our innovative softwares."},
];

const service_items = services.map((service) => 
  <li className="service-item" key={service.id}>
    <div className="service-head">{service.head}</div>
    <div className="service-desc">{service.descr}</div>
  </li>
);

const Services = () => {
  return (
    <div id="services" className="section service-section">
      <div className="container service-container">
        <div className="svg-star-container">
          <Star className="svg-star" />
        </div>
        <div className="heading">services we offer</div>
        <div className="service-list-container">
          <ul className="services-list">
            {service_items}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Services