import React from 'react'
import "./style.css"

const Header = () => {
  return (
    <header className="header">
      <nav className='nav head-container'>
        <a href="#about" className="nav_logo">xipi.tech</a>

        <div className="nav_menu">
          <ul className="nav_list grid">
          <li className="nav_item">
              <div className="nav_link_container">
                <a href="#about" className="nav_link">about</a>
              </div>
            </li>
            <li className="nav_item">
              <div className="nav_link_container">
                <a href="#services" className="nav_link">services</a>
              </div>
            </li>
            <li className="nav_item">
              <div className="nav_link_container">
                <a href="#contact" className="nav_link">contact us</a>
              </div>
            </li>
          </ul>
        </div>

        <div className="nav_toggle">

        </div>
      </nav>
    </header>
  )
}

export default Header