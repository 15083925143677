import React, { Component } from 'react'
import "./style.css"

export class Card extends Component {
  render() {
    return (
      <div className="card-container">
        <div className="svg-container">
          <this.props.svg className="svg" />
        </div>
        <div className="heading center-txt-h fig-text">
          {this.props.fig}
        </div>
        <div className="description">
          {this.props.desc}
        </div>
      </div>
    )
  }
}

export default Card