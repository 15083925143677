// import React, { useEffect, useRef } from 'react';
import './style.css'; // Create this CSS file for styling
import { ReactComponent as Asterisk } from "../../assets/asterisk.svg"
import { ReactComponent as Star } from "../../assets/star.svg"

const Hero = () => {
  return (
    <div className="shapes-container head-container">
      <ul className="shapes">
        <li className="shape">
          <Asterisk className="nipple" />
        </li>
        <li className="shape">
          <Star className="nipple" />
        </li>
      </ul>
    </div>
  );
};

export default Hero;
