import React from 'react'
import "./style.css"
import {ReactComponent as Logo} from "../../assets/logo.svg";
import {ReactComponent as XLogo} from "../../assets/twitterx.svg"
import {ReactComponent as InstaLogo} from "../../assets/instagram.svg"

const Footer = () => {
  return (
    <footer id="contact" className="section">
      <div className="container footer-container">
        <a className="logo-container center-h" href="#">
          <Logo className="logo footer-logo" />
        </a>
        <div className="social-container center-h">
          <ul className="social-list">
            <li className="social-item">
              <a href="https://twitter.com/xipitech" target="_blank" rel="noreferrer">
                <XLogo className="icon" />
              </a>
            </li>
            <li className="social-item">
              <a href="https://www.instagram.com/xipi.tech/" target="_blank" rel="noreferrer">
                <InstaLogo className="icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="end-credits center-txt-h">© 2023 xipi.tech.</div>
      </div>
    </footer>
  )
}

export default Footer