import React from 'react'
import "./style.css"
import {ReactComponent as Tag} from "../../assets/tag.svg";
import {ReactComponent as Projects} from "../../assets/projects.svg";
import {ReactComponent as Clients} from "../../assets/clients.svg";
import {ReactComponent as Asterisk} from "../../assets/asterisk.svg";
import Card from "../card/script";

const cards = [
  {id:0, svg:Projects, fig:"3", desc:"innovative projects"},
  {id:1, svg:Clients, fig:"10+", desc:"satisfied clients"},
  {id:2, svg:Asterisk, fig:"5+", desc:"years experience"}
];

const card_items = cards.map((card) =>
  <li className="card-item" key={card.id}>
    <Card svg={card.svg} fig={`${card.fig}`} desc={`${card.desc}`} />
  </li>
);

const Highlights = () => {
  return (
    <div className="section">
      <div className="container highlights-container">
        <div className="svg-tag-container">
          <Tag className="svg-tag" />
        </div>
        <div className="highlights-content">
          <div className="heading content-heading">customer-centric development</div>
          <ul className="highlights-list">
            <li className="hightlight-item">
              our passionate team of experts prioritizes customer satisfaction above everything else, ensuring a delightful experience with our products.
            </li>
            <li className="hightlight-item">
              with a perfect blend of technology and creativity, we transform your vision into reality.
            </li>
          </ul>
        </div>
        <div className="card-stack">
          <ul className="cards-list">
            {card_items}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Highlights