import React from 'react'
import "./style.css"
import {ReactComponent as Star} from "../../assets/star.svg";

const Tech = () => {
  return (
    <div id="tech" className="section">
      <div className="container tech-container">
        <div className="desc-container">
          <div className="heading">revolutionary technologies</div>
          <div className="desc">at xipi.tech, we harness cutting-edge advancements like AI/ML, AR, VR, and RPA to create software solutions that cater to the unique needs of customers.</div>
        </div>
        <div className="svg-container">
          <Star className="svg-img"></Star>
        </div>
      </div>
    </div>
  )
}

export default Tech