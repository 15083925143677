import './App.css';
import Header from './comps/header/script';
import Hero from './comps/hero/script';
import About from './comps/about/script';
import Technologies from './comps/tech/script';
import Highlights from './comps/highlights/script';
import Filler from './comps/filler/script';
import Services from './comps/services/script';
import Footer from './comps/footer/script';


function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Technologies />
      <Services />
      <Filler />
      <Highlights />
      <Footer />
    </>
  );
}

export default App;
